import styled from 'styled-components';
import {IAutocompleteApiSuggestion} from "../../../utils/autocompleteApiRequest/types";
import { ISuggestion } from '../../../utils/autocompleteRequest';
import { BREAKPOINT_S, BREAKPOINT_M, BREAKPOINT_L, COLORS } from '../../../constants';
import { MapMarkerIcon } from "../../icons/MapMarkerIcon/MapMarkerIcon";
import { normalizeOption } from "../../../utils/formatAutocompleteResult";

interface IProps {
  options: ISuggestion[] | IAutocompleteApiSuggestion[];
  onListItemSelect(suggestion: ISuggestion | IAutocompleteApiSuggestion, formattedOption: string | undefined): void;
  focusedOptionIndex?: number | null;
  useHapiAutocomplete?: boolean
}

const List = styled.ul`
  width: 100%;
  background-color: ${COLORS.white};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.3);
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  border-radius: 8px;
  padding: 16px 0;
`

const ListItemWrapper = styled.div<{
  focused?: boolean
}>`
  display: flex;
  align-items: center;
  width: 336px;
  background-color: ${props => props.focused ? COLORS.whiteHover : COLORS.white};

  &:hover, &focus {
    background-color: ${COLORS.whiteHover};
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    width: 388px;
  }
`

const ListItem = styled.li`
  font-size: 14px;
  cursor: pointer;
  padding: 18px 20px;
  width: 100%
`

const ListWrapper = styled.div`
  z-index: 2;
  margin-top: 8px;
  position: absolute;
  top: 40px;
  max-width: 70vw;

  @media (min-width: ${BREAKPOINT_S}px) {
    top: 50px;
    max-width: unset;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    top: 56px;
  }
`

const MapIconWrapper = styled.div`
  min-width: 12.52px;
  height: 16px;
  margin-left: 18px;

  &:hover {
    cursor: pointer;
  }

  svg {
    pointer-events: none;
  }
`

export const Autocomplete = ({ options, onListItemSelect, focusedOptionIndex, useHapiAutocomplete }: IProps) => {
  if (!options || options.length === 0) return null;

  const getAutocompleteApiOption = (option: IAutocompleteApiSuggestion) => {
    return option.type === 'property_suggestion' ? option.displayAddress : option.displayName
  }

  const getFormattedOption = (option: ISuggestion | IAutocompleteApiSuggestion) => {
    return useHapiAutocomplete ? getAutocompleteApiOption(option) : normalizeOption(option)
  }

  const getOptionKey = (option: ISuggestion | IAutocompleteApiSuggestion) => {
    return useHapiAutocomplete ? getAutocompleteApiOption(option) : option.place_name
  }

  const onOptionKeyDown = (e: React.KeyboardEvent, option: ISuggestion | IAutocompleteApiSuggestion) => {
    if (e.key === 'Enter') {
      onListItemSelect(option, getFormattedOption(option));
    }
  }

  return (
    <ListWrapper id="autocomplete-wrapper">
      <List>
        {options.map((option, ix) => (
          // TODO: Change the HTML structure to handle tab navigation by default, instead of setting a tabindex on this parent `div`
          <ListItemWrapper
            id={`autocomplete-option-${ix}`}
            tabIndex={0}
            focused={ix === focusedOptionIndex}
            key={`${getOptionKey(option)}-list-item`}
            onClick={() => {
              onListItemSelect(option, getFormattedOption(option));
            }}
            onKeyDown={e => onOptionKeyDown(e, option)}
          >
            <MapIconWrapper>
              <MapMarkerIcon
              />
            </MapIconWrapper>
            <ListItem>{getFormattedOption(option)}</ListItem>
          </ListItemWrapper>
        ))}
      </List>
    </ListWrapper>
  );
}
