import { useContext, useRef, useEffect, useState, ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'
import { autocompleteApiRequest } from '../../utils/autocompleteApiRequest'
import { IAutocompleteApiSuggestion } from '../../utils/autocompleteApiRequest'
import { ErrorBoundary } from '@sentry/react'
import { useSearchParams } from 'react-router-dom'
import { InputDecorator } from './InputDecorator'
import {
  coolGray50,
  darkBlue,
  coolGray30,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_XL,
  white,
  SLUG_FILTER
} from '../../constants'
import { Autocomplete } from './Autocomplete'
import { autocompleteRequest, ISearchLocation, ISuggestion } from '../../utils/autocompleteRequest'
import { PropertyType } from '../../contexts/PropertiesContext'
import { LocationContext, ILocationContext } from '../../contexts/LocationContext'
import { parseMapboxLocation } from '../../utils/parseMapboxLocation'
import { trackEvent, ACTION_SEARCH } from '../../utils/tracking'
import { getAreaFilters } from '../../utils/getAreaFilters'
import { hapiSearch } from './hapiSearch'
import { propertyPageUrl } from '../../utils/propertyPageUrl'
import { buildSearchUrlFromFilters } from '../../utils/buildSearchUrlFromFilters'
import { normalizeOption } from '../../utils/formatAutocompleteResult'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { SearchIcon } from '../icons/SearchIcon'
import { Flex } from '../common/Flex'
import {
  PropertySearchFiltersContext,
  IFiltersContext
} from '../../contexts/PropertySearchFiltersContext'
import { debounce } from '../../utils/debounce'
import { stateCodeFromSlug, stateNameOf } from '../../utils/stateUtils'
import { IFilter } from '../../utils/buildRequestFilters'

const SearchBarContainer = styled.div`
  padding: 16px 0;
  position: ${props => (props.showMap ? 'absolute' : 'sticky')};
  top: ${props => (props.showMap ? 56 : -8)}px;
  background: ${white};
  z-index: 4;
  ${props => {
    if (props.showMap) {
      return `
        left: 0;
        right:0;
        padding: 16px 16px 0 16px;
      `
    }
  }}

  @media (min-width: ${BREAKPOINT_S}px) {
    top: ${props => (props.showMap ? 64 : 0)}px;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    padding-top: 24px;
    padding-bottom: 0;
    top: ${props => (props.showMap ? '72px' : '0')};
    ${props => {
      if (props.showMap) {
        return `
          padding-left: 32px;
          padding-right: 32px;
      `
      }
    }}
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    position: sticky;
    top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  border: 1px solid ${coolGray50};
  border-radius: 6px;
  height: 40px;
  width: 100%;

  @media (min-width: ${BREAKPOINT_S}px) {
    height: 48px;
    width: 386px;
  }

  &:focus-within {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
`

const Input = styled.input`
  flex-grow: 2;

  outline: none;
  border: none;
  text-overflow: ellipsis;

  margin: 0 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  color: ${darkBlue};

  &:focus {
    outline: none;
  }

  ::placeholder {
    font-size: 16px;
    color: ${coolGray30};
  }
`

export const SearchBar = ({
  navigateOnSearch = false,
  showMap = false,
  mobileFilterPopoverOpen,
  style
}) => {
  // TODO: replace this feature flag with flagsmith
  const useHapiAutocomplete = process.env.REACT_APP_USE_HAPI_AUTOCOMPLETE_API === 'true'
  const { mobile } = useBreakpoint()
  const filterContext = useContext(PropertySearchFiltersContext) as IFiltersContext
  const [inputValue, setInputValue] = useState('')
  const [showClearButton, setShowClearButton] = useState(false)
  const [options, setOptions] = useState<ISuggestion[] | IAutocompleteApiSuggestion[]>([])
  const [selectedOption, setSelectedOption] = useState<
    ISuggestion | IAutocompleteApiSuggestion | null
  >(null)
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { setLocationTitle, setSearchLocation } = useContext(LocationContext) as ILocationContext
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    performSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  const clearInput = () => {
    setInputValue('')
    setShowClearButton(false)
    setOptions([])
    setFocusedOptionIndex(null)
  }

  const updateOptionFocus = (increment: number) => {
    if (!options.length) {
      return
    }
    if (focusedOptionIndex === null || Number.isNaN(focusedOptionIndex)) {
      setFocusedOptionIndex(0)
      return
    }

    const lastIndex = options.length - 1
    const newIndex = (focusedOptionIndex + increment) % options.length
    setFocusedOptionIndex(newIndex < 0 ? lastIndex : newIndex)
  }

  const handleSelectWithKey = () => {
    if (!options.length) {
      return
    }
    const targetIndex = focusedOptionIndex || 0
    const option = (options as any)[targetIndex]
    const formattedOption = useHapiAutocomplete ? option?.displayName : normalizeOption(option)
    handleSuggestionSelect(options[targetIndex], formattedOption || '')
    clearInput()
  }

  const getAutocompleteApiData = async (geography: string) => {
    const { data, error } = await autocompleteApiRequest(geography)
    const dataWithValidAddresses = data.flatMap(suggestion => {
      if (suggestion.type === 'property_suggestion') {
        return isSuggestionAddressValid(suggestion) ? suggestion : []
      } else {
        return suggestion
      }
    })

    return {
      data: dataWithValidAddresses,
      error
    }
  }

  const handleInputChange = async (geography: string) => {
    try {
      if (geography.length > 0) setShowClearButton(true)

      if (geography.length < 3) return null

      const { data, error } = useHapiAutocomplete
        ? await getAutocompleteApiData(geography)
        : await autocompleteRequest(geography)
      if (error) throw error

      setOptions(data)
    } catch (error) {
      // TODO: handle mapbox API and autocompleteAPI errors
      setOptions([])
    }
  }

  const debouncedChangeHandler = useMemo(
    () => debounce((geography: string) => handleInputChange(geography), 500),
    []
  )

  const updateInputValue = (ev: ChangeEvent<HTMLInputElement>, debounce) => {
    const geography = ev.target.value
    setInputValue(geography)
    debouncedChangeHandler(geography)
  }

  const parseAutocompleteApiLocation = (suggestion: IAutocompleteApiSuggestion) => {
    const { displayName, areaType, name, areaSlug } = suggestion
    const stateCode = stateCodeFromSlug(areaSlug)
    const stateName = stateNameOf(stateCode)

    const parsedLocation: ISearchLocation[] = [
      {
        areaValue: displayName,
        neighborhood: null,
        city: areaType === 'ZipCode' ? displayName?.split(',')[1].trim() : name,
        county: null,
        state: stateName
      }
    ]

    return parsedLocation
  }

  const goToPropertyPageUrl = (suggestion: IAutocompleteApiSuggestion) => {
    const formattedUuid = suggestion.uuid.replaceAll('-', '')
    window.location.assign(
      propertyPageUrl(
        suggestion.countyOrParish,
        suggestion.stateOrProvince,
        suggestion.hlFullAddress,
        suggestion.city,
        suggestion.postalCode,
        formattedUuid
      )
    )
    return null
  }

  const isSuggestionAddressValid = (suggestion: IAutocompleteApiSuggestion) => {
    const {
      id,
      countyOrParish,
      stateOrProvince,
      hlFullAddress,
      city,
      postalCode,
      uuid,
      displayAddress
    } = suggestion

    return Boolean(
      id &&
        countyOrParish &&
        stateOrProvince &&
        hlFullAddress &&
        city &&
        postalCode &&
        uuid &&
        displayAddress
    )
  }

  const handleSuggestionSelect = (
    suggestion: ISuggestion | IAutocompleteApiSuggestion,
    formattedOption: string | ''
  ) => {
    let searchLocation: ISearchLocation[]
    if (useHapiAutocomplete) {
      if (isSuggestionAddressValid(suggestion)) {
        goToPropertyPageUrl(suggestion)
      }

      searchLocation = parseAutocompleteApiLocation(suggestion)
    } else {
      searchLocation = parseMapboxLocation(suggestion)
    }

    setInputValue(formattedOption)
    setOptions([])
    setLocationTitle(formattedOption)
    setSelectedOption(suggestion)
    setSearchLocation(searchLocation)
  }

  const performSearch = async () => {
    if (selectedOption === null) {
      return null
    }

    if (searchParams.has('cmm')) {
      searchParams.delete('cmm')
      setSearchParams(searchParams)
    }

    trackEvent(ACTION_SEARCH, 'submits_search')
    let areaFilters: IFilter[]

    if (useHapiAutocomplete) {
      const { areaSlug } = selectedOption
      if (navigateOnSearch) {
        const searchUrl = `/${areaSlug}/homes-for-sale`
        window.location.assign(searchUrl)
      }

      areaFilters = [
        {
          ...SLUG_FILTER,
          value: areaSlug
        }
      ]
    } else {
      const { context, text, place_type: placeType, address } = selectedOption

      const isAddress = placeType[0] === 'address'

      areaFilters = getAreaFilters({
        context: context,
        text: text,
        placeType: placeType[0],
        address: address
      })

      if (isAddress) {
        const { data } = await hapiSearch(selectedOption)
        const property = data[0] as PropertyType

        if (isCondo(property)) {
          filterContext?.setLocationFilters(areaFilters)

          return null
        } else {
          window.location.assign(
            propertyPageUrl(
              property.countyOrParish,
              property.stateOrProvince,
              property.hlFullAddress,
              property.city,
              property.postalCode,
              property.uuidFormatted
            )
          )

          return null
        }
      } else if (navigateOnSearch) {
        const searchUrl = buildSearchUrlFromFilters(areaFilters)
        window.location.assign(searchUrl)
      }
    }

    filterContext?.setLocationFilters(areaFilters)
  }

  const isCondo = (property: PropertyType) => {
    return property?.unitNumber
  }

  const handleKey = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        handleSelectWithKey()
        break
      case 'ArrowDown':
        updateOptionFocus(1)
        break
      case 'ArrowUp':
        updateOptionFocus(-1)
        break
      case 'Escape':
        clearInput()
        break
    }
  }

  const handleBlur = (e: React.ChangeEvent) => {
    const currentTarget = e.currentTarget

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setOptions([])
      }
    }, 0)
  }

  const placeHolderText = 'Enter a city, ZIP code or address'

  const handleSearchButtonCLick = (ev: React.MouseEvent) => {
    const targetIndex = focusedOptionIndex || 0

    if (options[0]) {
      const option = (options as any)[targetIndex]
      const formattedOption = useHapiAutocomplete ? option?.displayName : normalizeOption(option)
      handleSuggestionSelect(options[targetIndex], formattedOption || '')
      clearInput()
    }
  }

  return (
    <ErrorBoundary>
      <SearchBarContainer
        showMap={showMap}
        mobileFilterPopoverOpen={mobileFilterPopoverOpen}
        style={style}
      >
        <Form
          onKeyPress={ev => (ev.key === 'Enter' ? ev.preventDefault() : null)}
          onBlur={handleBlur}
        >
          {mobile && (
            <Flex justify="center" align="center" pr="4px" pl="16px">
              <SearchIcon />
            </Flex>
          )}
          <Input
            title={placeHolderText}
            data-testid={'searchbar'}
            placeholder={placeHolderText}
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={e => updateInputValue(e, debounce)}
            onKeyDown={handleKey}
          />
          <InputDecorator
            mobile={mobile}
            showClearButton={showClearButton}
            onClearClick={clearInput}
            onSearchClick={handleSearchButtonCLick}
          />
          <Autocomplete
            options={options}
            onListItemSelect={handleSuggestionSelect}
            focusedOptionIndex={focusedOptionIndex}
            useHapiAutocomplete={useHapiAutocomplete}
          />
        </Form>
      </SearchBarContainer>
    </ErrorBoundary>
  )
}
